<template>
    <modal :name="nome" height="auto" width="600" :scrollable="true" :clickToClose="false">
        <CloseModal :close="closeModal"/>
        <div class="modalbox">
            <div class="modalbox-content">
                <CListGroup v-for="(campo, id) in campos" :key="id">
                    <CListGroupItem class="mb-1" @click="onSelectCampo(id)" href="#">
                        <strong>{{ campo }}</strong>
                        <br>
                        <small>{{ id }}</small>
                    </CListGroupItem>
                </CListGroup>
            </div>
        </div>
    </modal>
</template>

<script>
  import CloseModal from "../../../components/CloseModal";

  export default {
    name: "CamposDisponiveis",
    props: [
      'campos',
      'nome',
      'onSelect'
    ],
    components: {
      CloseModal,
    },
    methods: {
      closeModal() {
        this.$modal.hide(this.nome);
      },
      onSelectCampo(valor) {
        this.$modal.hide(this.nome);
        this.onSelect(valor)
      }
    },
  }
</script>

<style scoped>

</style>