<template>
    <table class="table table-hover table-sm">
        <tr>
            <th>Tipo</th>
            <th>Campos mapeados</th>
            <th></th>
        </tr>

        <tr v-for="(tipoObjeto, tipoChave) in tipos">
            <td>
                <strong>{{ tipoObjeto.titulo }}</strong>
                <br>
                <small>Chave: {{ tipoChave }}</small>
            </td>
            <td>{{ campos[tipoChave] ? campos[tipoChave].length : 0 }}</td>
            <td class="text-right">
                <button
                    type="button"
                    class="button button-primary button-sm"
                    @click="onEdit(tipoChave)"
                >
                    <fa-icon icon="pencil-alt" />
                </button>
            </td>
        </tr>
    </table>
</template>

<script>
  export default {
    name: "ListaCampos",
    props: [
      'tipos',
      'campos',
      'onEdit'
    ]
  }
</script>
